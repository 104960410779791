import React from 'react';
import useAppContext from 'lib/hooks/useAppContext';
import BrandedLandingPageLayout from 'routes/branded_landing_page/BrandedLandingPageLayout';
import ATKHomePage from 'routes/atk_homepage/ATKHomePage';
import parseUser from 'lib/utils/parseUser.ts';
import utils from '../lib/utils';

const AtkHomepageLoggedOut = () => {
  const { url } = useAppContext();
  return (
    <BrandedLandingPageLayout
      canonical={`${url}atk`}
      meta={{
        title:
          "America's Test Kitchen: Recipes & Reviews You Can Trust | Stream Every Episode | America’s Test Kitchen",
        description:
          'Learn our unique approach to crafting the best recipes, plus unbiased product reviews, time-saving tips & clever hacks from our test cooks and kitchen experts.',
      }}
    >
      <ATKHomePage />
    </BrandedLandingPageLayout>
  );
};

AtkHomepageLoggedOut.getInitialProps = async ({ req, res, query }) => {
  const { reducedSegment } = parseUser(req);
  const url = utils.requestToUrl(req);
  const queryString = Boolean(Object.keys(query).length)
    ? `?${new URLSearchParams(query).toString()}`
    : '';
  const subdomain = url.match(/:\/\/([a-z-23]*)/)?.[1] ?? 'www';
  const urlRedirection =
    subdomain === 'localhost'
      ? `/browse${queryString}`
      : `https://${subdomain}.americastestkitchen.com/browse${queryString}`;
  if (res && reducedSegment === 'multisite') {
    // Server-side
    res.writeHead(302, {
      Location: urlRedirection,
    });
    res.end();
    return {};
  }
  return {};
};

export default AtkHomepageLoggedOut;
